'use strict';

app.directive('actions', function () {
	return {
		restrict: 'E',
		scope: {
			user: '=',
			isAdmin: '<',
			deleteUser: '&',
			updateUser: '&'
		},
		templateUrl: 'scripts/features/configuration/users_configuration/actions.html'
	};
})
	.directive('privileges', function () {
		return {
			restrict: 'E',
			scope: {
				user: '=',
				isAdmin: '<'
			},
			templateUrl: 'scripts/features/configuration/users_configuration/privileges.html'
		};
	})
	.controller(
		'UsersConfigurationController',
		function UsersConfigurationController(
			$scope,
			$rootScope,
			Employees,
			Employee,
			dialogSrv,
			toastr,
			AddEmployeeService,
			Authentication,
			$translate,
			$q,
			ReactModals,
			CrossApplicationService,
			CROSS_APP_ACTIONS
		) {
			$scope.showSSOLoginInfo = Employee.showSSOLoginInfo;
			$scope.usersGrid = {
				data: [],
				rowHeight: 50,
				showGridFooter: true,

				onRegisterApi: function (gridApi) {
					$scope.gridApi = gridApi;
					$scope.gridApi.grid.registerRowsProcessor($scope.filterByPrivilieges, 100);
				},

				columnDefs: [
					{
						field: 'profile_image',
						displayName: '',
						cellTemplate:
							'<div class="ui-grid-cell-contents ng-binding ng-scope"><img class="profile-image-tiny" ng-src="{{ row.entity.profile_image }}"/></div>',
						maxWidth: 60,
						enableColumnMenu: false,
						enableCellEdit: false,
						sortable: false
					},
					{
						field: 'name',
						cellTemplate:
							'<div class="ui-grid-cell-contents ng-binding ng-scope"><a ng-href="/#/drivers/{{ row.entity.id }}">{{ row.entity.name }}</a></div>',
						enableColumnMenu: false,
						enableCellEdit: false
					},
					{
						field: 'email',
						enableColumnMenu: false,
						enableCellEdit: false
					},
					{
						field: 'phone',
						enableColumnMenu: false,
						enableCellEdit: false
					},
					{
						name: 'Privileges',
						cellTemplate:
							'<div class="ui-grid-cell-contents ng-binding ng-scope"><privileges user="row.entity" is-admin="grid.appScope.isAdmin" /></div>',
						enableColumnMenu: false,
						enableCellEdit: false
					},
					{
						name: 'Login Method',
						cellTemplate:
							'<div class="ui-grid-cell-contents ng-binding ng-scope">' +
							'{{ grid.appScope.getLoginMethod(row.entity) }}' +
							'</div>',
						enableColumnMenu: false,
						enableCellEdit: false,
						visible: $scope.showSSOLoginInfo // Use the dynamic flag to show/hide the column
					},
					{
						name: 'Actions',
						cellTemplate:
							'<div class="ui-grid-cell-contents ng-binding ng-scope"><actions user="row.entity" is-admin="grid.appScope.isAdmin"' +
							'delete-user="grid.appScope.deleteUser(row.entity)" update-user="grid.appScope.updateUser(row.entity)"/></div>',
						enableColumnMenu: false,
						enableCellEdit: false
					}
				]
			};

			$scope.getLoginMethod = function (entity) {
				return entity?.allow_username_password_loginSSO
					? $translate.instant('USER_CONFIGURATION_VIEW.SSO_USER_PASSWORD')
					: $translate.instant('USER_CONFIGURATION_VIEW.SSO_ONLY');
			};

			$scope.isAdmin = Authentication.currentUser().admin;

			$rootScope.$watch($rootScope.canLogin, function () {
				if ($rootScope.canLogin) {
					$q.all([Employees.fetchAllAdminsAndDispatchers(), Employees.allEmployees]).then(function (results) {
						const driversAdminsDispatchers = Object.values(Employees.employeeByIdMap);
						$scope.usersGrid.data = driversAdminsDispatchers;
						$scope.users = driversAdminsDispatchers;
					});
				}
			});

			$scope.deleteUser = function (user) {
				if (confirm($translate.instant('DELETE_USER_CONFIRMATION_MESSAGE') + user.name + '?')) {
					Employee.delete(user.id)
						.then(function () {
							dialogSrv.notifyDialog(
								user.name + ' ' + $translate.instant('DELETE_USER_DIALOG.SUCCESSFULLY')
							);
						})
						.catch(function (error) {
							const toastrTitle = [1, 2, 3, 4].includes(error.rc)
								? $translate.instant('DELETE_USER_DIALOG.ERROR_CODE.RC' + error.rc)
								: error.details || undefined;
							dialogSrv.errorDialog(
								$translate.instant('DELETE_USER_DIALOG.ERROR_MESSAGE') + ' ' + user.name,
								toastrTitle
							);
						});
				}
			};

			//==============================================
			// Filet in users list
			//==============================================
			$scope.userViewFiler = {
				selection: 0,
				items: [
					{ label: $translate.instant('USER_CONFIGURATION_VIEW.ALL'), filter: _.constant(true) },
					{ label: $translate.instant('USER_CONFIGURATION_VIEW.ADMINS'), filter: _.matches({ admin: true }) },
					{
						label: $translate.instant('USER_CONFIGURATION_VIEW.DRIVERS'),
						filter: _.matches({ driver: true })
					},
					{
						label: $translate.instant('USER_CONFIGURATION_VIEW.DISPATCHERS'),
						filter: _.matches({ dispatcher: true })
					},
					{
						label: $translate.instant('USER_CONFIGURATION_VIEW.ADMINS_ONLY'),
						filter: _.matches({ admin: true, driver: false, dispatcher: false })
					},
					{
						label: $translate.instant('USER_CONFIGURATION_VIEW.DRIVERS_ONLY'),
						filter: _.matches({ admin: false, driver: true, dispatcher: false })
					},
					{
						label: $translate.instant('USER_CONFIGURATION_VIEW.DISPATCHERS_ONLY'),
						filter: _.matches({ admin: false, driver: false, dispatcher: true })
					}
				]
			};

			$scope.userViewFilterSelection = $scope.userViewFiler.items[0].filter;

			$scope.$watch('userViewFiler.selection', function (newValue, oldValue) {
				if (newValue === oldValue) {
					return;
				}

				$scope.userViewFilterSelection = newValue.filter;

				// refresh the grid to-refilter the data
				$scope.gridApi.grid.refresh();

				// re-set scroll position because ui-grid don't do that for some reaso
				// see https://github.com/angular-ui/ui-grid/issues/3205#issuecomment-204457854
				$scope.gridApi.core.scrollTo($scope.usersGrid.data[0], $scope.usersGrid.columnDefs[0]);
			});

			$scope.$on('employees list update', function () {
				// refresh list, to update filters
				// we are using queueRefresh instead of refresh, because `employee list update` might happen a lot
				$scope.gridApi.grid.queueRefresh();
			});

			$scope.filterByPrivilieges = function (users) {
				return _.filter(users, function (row) {
					return $scope.userViewFilterSelection(row.entity);
				});
			};

			//==============================================
			// Manage users
			//==============================================
			$scope.handleAddNewDriverClick = function () {
				return ReactModals.openEmployeeModal({
					title: '',
					showUserTypeSelection: true,
					defaultUserType: 'dispatcher',
					afterClose: () => {},
					onCancel: () => {}
				});
			};

			$scope.updateUser = function (user) {
				Employee.update(user.id, user)
					.then(function (user) {
						toastr.success('User ' + user.name + ' updated');
					})
					.catch(function (error) {
						var errorString = '';
						if (typeof error.details === 'string') {
							errorString = error.details;
						} else {
							for (var messageIdx in error.details) {
								errorString += messageIdx + ':' + error.details[messageIdx][0] + '\r\n';
							}
						}
						toastr.error(errorString);
					});
			};

			$rootScope.$on('employees list update', function () {
				$scope.users = Object.values(Employees.employeeByIdMap);
				$scope.filterData();
			});

			CrossApplicationService.on(CROSS_APP_ACTIONS.DRIVER_CREATED, user => {
				Employees.updateEmployee(user);
			});

			//==============================================
			// filter users
			//==============================================
			$scope.filter = { term: '' };
			$scope.filterData = function () {
				$scope.usersGrid.data = _.filter($scope.users, function (driver) {
					if (_.isEmpty($scope.filter.term)) return true;

					var query = $scope.filter.term.toLowerCase();

					return _.chain(driver)
						.pick('name', 'email', 'phone')
						.some(function (value) {
							return value && value.toString().toLowerCase().indexOf(query) >= 0;
						})
						.valueOf();
				});
			};

			$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
				if (!isLoggedIn) {
					return;
				}
			});
		}
	);
