'use strict';

angular.module('bringgApp').service('LinkedTasksService', function (TasksData, TASK_STATUS) {
	const _this = this;
	_this.getMissingLinkedTasks = getMissingLinkedTasks;

	function getMissingLinkedTasks(selectedTasks, shouldFilterLinkedDoneTasks) {
		const linkedTasksIds = new Set();

		selectedTasks.forEach(task => {
			if (task.linked_task_id) {
				if (linkedTasksIds.has(task.id)) {
					linkedTasksIds.delete(task.id);
				} else {
					linkedTasksIds.add(task.linked_task_id);
				}
			}
		});

		const linkedTasks = Array.from(linkedTasksIds).map(linkedTaskId => TasksData.get(linkedTaskId));
		if (shouldFilterLinkedDoneTasks) {
			const activeLinkedTasks = linkedTasks.filter(task => task && task.status !== TASK_STATUS.DONE);

			return activeLinkedTasks;
		}
		return linkedTasks;
	}
});
