'use strict';

angular
	.module('bringgApp')
	.controller(
		'PlanningTabController',
		function (
			$scope,
			$timeout,
			Task,
			Tasks,
			TasksLoader,
			WayPointsService,
			MerchantConfigurations,
			DriverConfigurations,
			Teams,
			Employees,
			EmployeesTasks,
			DispatchListService,
			toastr,
			DispatcherPrintService,
			dialogSrv,
			$window,
			Authentication,
			OptimizeModalService,
			$q,
			GridColumnsService,
			DEFAULT_RT_THROTTLE,
			Application,
			TasksModalService,
			Tags,
			$rootScope,
			RunsService,
			PRIVILEGES_TYPES,
			PlannedRoutesService,
			TranslationService,
			UserTypes,
			RouteAssigner,
			TasksGrouping,
			VehiclesService,
			VehicleTypesService,
			LoadConstraintsService,
			SkillsService,
			RoutesPlannerService,
			CrossApplicationService,
			PartialRunsSources,
			GRID_DATA,
			AssignDriverValidations,
			ReactModals,
			OPTIMIZATION_TYPES,
			optimizationTypesService,
			OptimizationTasksValidationService,
			ManagedAttributesService,
			CustomAttributesService,
			LinkedTasksService,
			ServiceAreasService,
			TasksData,
			SlickGridConfigService,
			CROSS_APP_ACTIONS,
			PresetViewsService,
			ObjectUtilsService,
			CollapsedLinkedTasksService
		) {
			const NO_ROUTE = -1;
			const GRID_COLUMNS_KEY = 'planning_fields';
			const emptyFilteringEnabled = Authentication.currentUser().feature_flags.empty_filters_planning_backend;
			let _realtimeEtaEnabled;
			$scope.hideActions = true;
			$scope.merchantConfiguration = _.clone(MerchantConfigurations);
			$scope.onlineFilter = Employees.filterIsOnline;
			$scope.currentUser = Authentication.currentUser();
			$scope.tags = [];
			$scope.teams = [];
			$scope.pendingReleaseRequest = false;
			$scope.admin = Authentication.currentUser().admin;
			$scope.canCustomizeColumn = Authentication.currentUser().has_access(PRIVILEGES_TYPES.CUSTOMIZE_LIST_LAYOUT);
			$scope.canCancel = Authentication.currentUser().has_access(PRIVILEGES_TYPES.CANCEL_TASK);
			$scope.canSendToDrivers = Authentication.currentUser().has_access(PRIVILEGES_TYPES.SEND_TO_DRIVERS);
			$scope.canLockUnlock = Authentication.currentUser().has_access(PRIVILEGES_TYPES.LOCK_UNLOCK_TASK);
			$scope.canEditTaskDetails = Authentication.currentUser().has_access(PRIVILEGES_TYPES.EDIT_TASK_DETAILS);
			$scope.isPlanning = true;
			$scope.gaPage = 'PlanningView';
			$scope.gridData = GRID_DATA.PLANNING;
			$scope.isOptimizationNewAndSaveExistingDisabled = true;
			$scope.optimizeNewButtonTooltip = 'OPTIMIZE_BUTTON.OPTIMIZE_NEW_AND_SAVE_EXISTING_LABEL';

			$scope.onColumnsSave = function () {
				$scope.customizeListColumnsShown = false;
				$scope.initGridColumns();
				$scope.grid.setColumns($scope.gridColumns);
				$scope._init();
			};

			var gridId = GRID_DATA.PLANNING.id;
			var dynamicCapacityMode = MerchantConfigurations.dynamic_capacity_values_mode;

			$scope.initGridColumns = function () {
				$scope.gridColumns = [checkboxSelector.getColumnDefinition()].concat(
					GridColumnsService.getConvertedColumnsFromMerchantConfigurations(null, GRID_COLUMNS_KEY)
				);
			};

			//==============================================
			// DataGrid functionality START
			//==============================================
			var checkboxSelector = GridColumnsService.initGrid($scope);

			$scope.employeeFunct = EmployeesTasks.formatEmployeeSelection;

			var w = angular.element($window);

			$scope.canOpenPlannedRunsUserAssigner = RouteAssigner.canOpenPlannedRunsUserAssigner;
			$scope.isAllowedToBulkAssign = false;
			$scope.assignButtonTooltipText = '';

			function calculateBulkAssignStatus() {
				const isAllowedAnswer = AssignDriverValidations.isAllowedToBulkAssign($scope.selectedItems);
				$scope.isAllowedToBulkAssign = isAllowedAnswer.canAssign;

				$scope.assignButtonTooltipText =
					isAllowedAnswer.canAssign || !isAllowedAnswer.errMessage
						? ''
						: TranslationService.instant(isAllowedAnswer.errMessage);

				$timeout(() => angular.element('#driverSelect').trigger('click'));
			}

			function isRouteAssignedToResourceOnCombinedOptimization() {
				const optimizationType =
					optimizationTypesService.getConfiguredOptimizationType($scope.selectedItems[0].team_id) ||
					OPTIMIZATION_TYPES.ANONYMOUS;

				if (optimizationType !== OPTIMIZATION_TYPES.COMBINED) {
					return true;
				}

				const selectedRoutes = new Set();
				$scope.selectedItems.forEach(task => task.run_id && selectedRoutes.add(task.run_id));

				return Array.from(selectedRoutes.values()).some(routeId => {
					const route = RunsService.getFromStore(routeId);
					return !!route.user_id || !!route.vehicle_id;
				});
			}

			function validateOptimizationButtons() {
				if (!$scope.selectedItems || $scope.selectedItems.length === 0) {
					$scope.isOptimizationNewAndSaveExistingDisabled = true;
					$scope.optimizeNewButtonTooltip = 'OPTIMIZE_BUTTON.SELECT_FULL_ROUTE_ERROR';
					return;
				}

				const isUnassignedTasksInList = $scope.selectedItems.find(task => !task.run_id);

				if (!isUnassignedTasksInList) {
					$scope.isOptimizationNewAndSaveExistingDisabled = true;
					$scope.optimizeNewButtonTooltip = 'OPTIMIZE_BUTTON.SELECT_NEW_TASKS_ERROR';
					return;
				}
				const assignedTask = $scope.selectedItems.find(task => task.run_id);

				if (assignedTask) {
					const partialRuns = RunsService.getPartialRunIdsByTasks(
						$scope.selectedItems,
						TasksData.getPlanningTasks()
					);
					if (Object.keys(partialRuns).length !== 0) {
						$scope.isOptimizationNewAndSaveExistingDisabled = true;
						$scope.optimizeNewButtonTooltip = 'OPTIMIZE_BUTTON.SELECT_FULL_ROUTE_ERROR';
						return;
					}
				}

				const isRouteAssignedToResource = isRouteAssignedToResourceOnCombinedOptimization();
				if (!isRouteAssignedToResource) {
					$scope.isOptimizationNewAndSaveExistingDisabled = true;
					$scope.optimizeNewButtonTooltip = 'OPTIMIZE_BUTTON.SELECT_ROUTE_ASSIGNED';
					return;
				}

				$scope.isOptimizationNewAndSaveExistingDisabled = false;
				$scope.optimizeNewButtonTooltip = 'OPTIMIZE_BUTTON.OPTIMIZE_NEW_AND_SAVE_EXISTING_LABEL';
			}
			function onSelectItem() {
				calculateBulkAssignStatus();
				validateOptimizationButtons();
			}

			$scope.$watch('selectedItems.length', onSelectItem);

			$scope.assignDriver = function (data) {
				let planningTasks = TasksData.getPlanningTasks();
				if (CollapsedLinkedTasksService.isCollapseLinkedTaskEnabled()) {
					if (data.sourceTasks) {
						data.sourceTasks = CollapsedLinkedTasksService.extractTasksFromCollapsedTasks(data.sourceTasks);
					}
					if (planningTasks) {
						planningTasks = CollapsedLinkedTasksService.extractTasksFromCollapsedTasks(planningTasks);
					}
				}

				RouteAssigner.assignDriver(
					data,
					handleListUpdate,
					{
						tasks: planningTasks,
						sourceRun: RunsService.getFromStore(data.runId),
						sourceTasks: data.sourceTasks,
						plannedRoutes: $scope.plannedRoutes,
						userTypes: $scope.userTypes,
						employees: $scope.employees,
						vehicleTypeByVehicleId: $scope.vehicleTypeByVehicleId
					},
					{
						isClusterModeEnabled: $scope.isClusterModeEnabled,
						doNotMultipleWeightByQuantity: $scope.doNotMultipleWeightByQuantity,
						dynamicCapacityMode: dynamicCapacityMode,
						readyToExecute: false,
						allowedActions: data.allowedActions
					}
				);
			};

			w.on('handleAssignRunToDriver', function (event, data) {
				$scope.assignDriver(data);
			});

			w.on('handleAssignRunToVehicle', function (event, data) {
				RouteAssigner.assignVehicle(data, TasksData.getPlanningTasks(), handleListUpdate);
			});

			w.on('handleAssignRunToTrailer', function (event, data) {
				RouteAssigner.assignTrailer(data, TasksData.getPlanningTasks(), handleListUpdate);
			});

			function closeSelectEmployee(task) {
				$('#s2id_list_task_' + task.id + '_employee_list').hide();
				$('#list_task_' + task.id + '_employee').show();
			}

			w.on('handleTaskGridDriverFilter', function (event, row) {
				var task = _.find($scope.tasks, function (item) {
					return item.id === row;
				});

				if ($scope.canOpenPlannedRunsUserAssigner()) {
					return $scope.assignDriver({ sourceTasks: [task] });
				}

				$('#list_task_' + task.id + '_employee').hide();
				var existingSelection = $('#s2id_list_task_' + task.id + '_employee_list');
				if (existingSelection.length !== 0) {
					// isEmpty doesn't work here
					existingSelection.show();
					existingSelection.select2('open');
					return;
				}

				var floatingInventoryApp = _.find(MerchantConfigurations.applications, {
					uuid: Application.APPLICATIONS.FloatingInventoryApp
				});

				var employees = floatingInventoryApp
					? Tasks.getDriversWithAvailableInventories($scope.employees, task.id)
					: Promise.resolve($scope.employees);

				employees.then(function () {
					$('#list_task_' + task.id + '_employee_list')
						.show()
						.select2({
							data: {
								results: EmployeesTasks.allEmployeesWithUnassigned(
									$scope.employees,
									task.team_ids,
									true
								),
								text: 'name'
							},
							formatResult: EmployeesTasks.formatEmployeeSelection,
							formatSelection: EmployeesTasks.formatEmployeeSelection,
							dropdownAutoWidth: true,
							//        query: function(query){ query.callback(); }
							sortResults: EmployeesTasks.optimizedEmployeeListForTask
						})
						.on('change', function (e) {
							closeSelectEmployee(task);
							$scope.assignEmployee(task, e.val).then(function () {
								$(this).select2('refresh');
							});
						})
						.on('select2-close', function () {
							closeSelectEmployee(task);
						})
						.select2('open');
				});
			});

			w.on('handleEditRun', function (event, runId) {
				const run = RunsService.getFromStore(runId);

				ReactModals.openRunEditorModal({
					plannedRoutes: $scope.plannedRoutes.filter(plannedRoute => plannedRoute.team_id === run.team_id),
					source: {
						run
					},
					title: TranslationService.instant('RUN_EDITOR.TITLE'),
					subtitle: TranslationService.instant('RUN_EDITOR.SUBTITLE'),
					errorMessage: TranslationService.instant('RUN_EDITOR.FAILED_TO_UPDATE_RUN'),
					onClose: function (wasUpdated) {
						if (wasUpdated) {
							handleListUpdate();
						}
					},
					isOverlappedByScheduledTimeRange: function (runId, plannedRouteId) {
						const runsByPlannedRouteId = getRunsByPlannedRouteId();
						const runs = runsByPlannedRouteId[plannedRouteId];
						if (!runs) {
							return false;
						}

						const run = RunsService.getFromStore(runId);
						runs.push(run);

						return RunsService.getOverlappedRunIds(runs).length > 1;
					}
				});
			});

			$scope.$on('$destroy', function () {
				w.off('handleTaskGridDriverFilter');
				w.off('handleAssignRunToDriver');
				w.off('handleTaskGridPrintReceiptFilter');
				w.off('handleAssignRunToVehicle');
				w.off('handleAssignRunToTrailer');
				w.off('handleEditRun');
				CrossApplicationService.off('FILTER_CHANGE', refreshFiltersOnFiltersChange);
				CrossApplicationService.off(CROSS_APP_ACTIONS.PRESET_VIEWS_UPDATED, $scope._init);

				$('[id^=s2id_list_task_]').remove();
				$('[id^=list_task_]').select2('destroy').remove();
				$('.select2-hidden-accessible').remove();
				$('.select2-drop').remove();

				subscriptions.forEach(function (item) {
					item();
				});
				subscriptions = [];
			});

			$scope.assignEmployee = function (task, employee_id) {
				return Tasks.assignTask(task, employee_id).then(
					function () {
						if (task.user_id) {
							toastr.success(TranslationService.instant('GLOBAL.TASK_ASSIGNED'));
						} else {
							toastr.success(TranslationService.instant('GLOBAL.TASK_UNASSIGNED'));
						}
					},
					function (reason) {
						if (reason) {
							toastr.error(reason);
						}
					}
				);
			};

			$scope.filterFunc = GridColumnsService.filterFunc;

			//==============================================
			// DataGrid functionality END
			//==============================================
			$scope.handleBulkAssignEmployeeSelected = function (item) {
				const isAllowedAnswer = AssignDriverValidations.isAllowedToAssignSelectedTasks($scope.selectedItems);
				if (isAllowedAnswer.errMessage && isAllowedAnswer.errMessage.length > 0) {
					toastr.error(TranslationService.instant(isAllowedAnswer.errMessage));
				}

				if (!isAllowedAnswer.canAssign || isAllowedAnswer.assignableTasks.length === 0) {
					return;
				}

				if (item && item.id) {
					Tasks.massAssign(item.id, isAllowedAnswer.assignableTasks)
						.then(function (result) {
							/*
							The first result is from SDK (V2)
							If there's error, the SDK catches exception and returns result = false
						*/
							if (result === true || result.success) {
								toastr.success(
									TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_ASSIGNED') + ' ' + item.name
								);
							} else {
								toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_ASSIGNED_FAILED'));
							}
						})
						.catch(function () {
							toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_ASSIGNED_FAILED'));
						});
				} else if (_.isNull(item.id)) {
					Tasks.massUnassign(isAllowedAnswer.assignableTasks)
						.then(function (result) {
							/*
							The first result is from SDK (V2)
							If there's error, the SDK catches exception and returns result = false
						*/
							if (result === true || result.success) {
								toastr.success(TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_UNASSIGNED'));
							} else {
								toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_UNASSIGNED_FAILED'));
							}
						})
						.catch(function () {
							toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_UNASSIGNED_FAILED'));
						});
				} else {
					toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_SELECT_DRIVER'));
				}
			};

			//==============================================
			// Grouping START
			//==============================================

			$scope.groupByOptions = DispatchListService.groupByOptions;

			$scope.handleGroupingSelectionChange = function (item) {
				var extraDisplayData = {
					Driver: function (group) {
						if (group.rows.length > 0 && group.rows[0].user) {
							const weightData = LoadConstraintsService.getWeightAndTotalWeight(
								{ vehicleTypeByVehicleId: $scope.vehicleTypeByVehicleId },
								group.rows,
								{
									isClusterModeEnabled: $scope.isClusterModeEnabled,
									doNotMultipleWeightByQuantity: $scope.doNotMultipleWeightByQuantity,
									dynamicCapacityMode: dynamicCapacityMode
								}
							);
							const totalWeightText = LoadConstraintsService.prepareLoadConstraintHtml(weightData);

							const totalPriceText = prepareDriverPriceText(group.rows);
							return totalWeightText + totalPriceText;
						}

						return '';
					},
					Route: function (group) {
						return prepareDriverPriceText(group.rows);
					}
				};

				$scope.groupByValues = TasksGrouping.groupBy(
					item,
					{
						employees: $scope.employees,
						teams: $scope.teams,
						tasks: $scope.tasks,
						vehicleTypeByVehicleId: $scope.vehicleTypeByVehicleId,
						vehiclesById: $scope.vehiclesById,
						vehicleTypes: $scope.vehicleTypes,
						userTypes: $scope.userTypes,
						plannedRoutes: $scope.plannedRoutes,
						selectedRows: $scope.selectedRows,
						overlappingRunIds: $scope.overlappingRunIds,
						filterObject: $scope.filterObject
					},
					{
						showEmptyGroups: false,
						isClusterModeEnabled: $scope.isClusterModeEnabled,
						doNotMultipleWeightByQuantity: $scope.doNotMultipleWeightByQuantity,
						dynamicCapacityMode,
						realtimeEtaEnabled: _realtimeEtaEnabled,
						allowModifyPlannedRouteIdentifier: $scope.allowModifyPlannedRouteIdentifier,
						updateTasks: function (tasks) {
							$scope.tasks = tasks;
						}
					},
					extraDisplayData
				);

				$scope.groupingModel = { selectedOption: item };
				SlickGridConfigService.saveGrouping(gridId, 'planning_taskGridGroupMode', item.value);
			};

			$scope.groupingModel = { selectedOption: null }; // the only way to force ui-select react on value changed from parent component

			$scope.initGroupingModel = function () {
				const savedGrouping =
					SlickGridConfigService.getGrouping(gridId, 'planning_taskGridGroupMode') ||
					TasksGrouping.GROUP_TYPES.Team;

				$scope.groupingModel = {
					selectedOption:
						_.find($scope.groupByOptions, { value: savedGrouping }) || _.first($scope.groupByOptions)
				};
				$scope.handleGroupingSelectionChange($scope.groupingModel.selectedOption);
			};

			//==============================================
			// Add Task START
			//==============================================

			$scope.handleCancelOrdersClicked = function () {
				if (!$scope.selectedItems.length) {
					toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_NO_ORDERS'));
					return;
				}
				const tasksToCancel = $scope.selectedItems;
				ReactModals.openReasonToCancelTaskModal({
					tasks: tasksToCancel,
					onCancelTasks: _.noop
				});
			};

			$scope.addTask = function () {
				if ($scope.readonly) {
					return;
				}

				const selectedTeamId =
					$scope.filterObject &&
					$scope.filterObject.selectedTeams &&
					$scope.filterObject.selectedTeams.length === 1
						? $scope.filterObject.selectedTeams[0]
						: undefined;
				TasksModalService.addTask(undefined, undefined, selectedTeamId);
			};

			$scope.addTaskCSV = function () {
				if ($scope.readonly) {
					return;
				}

				TasksModalService.addTaskCSV();
			};

			$scope.showOptimizeNewAndSaveExisting = function () {
				return (
					$scope.groupingModel.selectedOption?.value === 'TeamRoute' ||
					$scope.groupingModel.selectedOption?.value === 'Route'
				);
			};

			function validateOptimizationTasks(automaticOptimization, selectedTasks) {
				const optimizationType =
					optimizationTypesService.getConfiguredOptimizationType(selectedTasks[0].team_id) ||
					OPTIMIZATION_TYPES.ANONYMOUS;

				const missingLinkedTasks = LinkedTasksService.getMissingLinkedTasks(
					selectedTasks,
					$scope.shouldFilterLinkedDoneTasks
				);
				if (missingLinkedTasks) {
					selectedTasks = [...missingLinkedTasks, ...selectedTasks];
				}
				const { errors, allowedToProceed, type } = OptimizationTasksValidationService.validate(selectedTasks);

				const invalidTasksIds = _.flatten(Object.values(errors).map(taskIds => Array.from(taskIds)));

				if (Object.keys(errors).length > 0) {
					const onProceedToOptimization = () =>
						$scope.openOptimizationModal(
							optimizationType,
							automaticOptimization,
							selectedTasks,
							invalidTasksIds
						);
					ReactModals.openOptimizationErrorModal({
						errors,
						type,
						onProceedToOptimization: allowedToProceed && onProceedToOptimization,
						canProceedToOptimization: allowedToProceed && invalidTasksIds.length !== selectedTasks.length
					});
				} else {
					$scope.openOptimizationModal(optimizationType, automaticOptimization, selectedTasks);
				}
			}

			$scope.openOptimizationModal = function (
				optimizationType,
				automaticOptimization,
				selectedTasks,
				invalidTasksIds
			) {
				let validTasks = selectedTasks;
				if (invalidTasksIds && invalidTasksIds.length) {
					validTasks = selectedTasks.filter(task => !invalidTasksIds.includes(task.id));
				}
				OptimizeModalService.open({
					dispatch: false,
					automaticOptimization,
					selectedTasks: validTasks,
					drivers: $scope.employees,
					type: optimizationType
				});
			};

			//==============================================
			// Route Optimizations
			//==============================================
			$scope.handleOptimizeSelectedClicked = function () {
				const optimizationType =
					optimizationTypesService.getConfiguredOptimizationType($scope.selectedItems[0].team_id) ||
					OPTIMIZATION_TYPES.ANONYMOUS;

				if (optimizationType === OPTIMIZATION_TYPES.COMBINED) {
					ReactModals.setIsOptimizationModalOpenerOpen({
						selectedTasks: $scope.selectedItems,
						isOptimizeNewAndSaveExisting: false
					});
				} else {
					validateOptimizationTasks(false, $scope.selectedItems);
				}
			};

			$scope.handleOptimizeNewAndSaveExistingClicked = function () {
				var selectedTasksGroupedByRoute = $scope.groupTasksByRun($scope.selectedItems);
				var tasksByRun = $scope.groupTasksByRun(TasksData.getPlanningTasks());
				const missingLinkedTasks = LinkedTasksService.getMissingLinkedTasks(
					$scope.selectedItems,
					$scope.shouldFilterLinkedDoneTasks
				);
				const selectedItems = [...$scope.selectedItems, ...missingLinkedTasks];

				for (var runId in selectedTasksGroupedByRoute) {
					if (Number(runId) === NO_ROUTE) {
						continue;
					}

					if (selectedTasksGroupedByRoute[runId].length !== tasksByRun[runId].length) {
						toastr.error(TranslationService.instant('PLANNING.SELECT_ALL_ORDERS_IN_ROUTE'));
						return;
					}
				}

				var tasksWithSecondWayPoints = $scope.getTasksWithSecondWayPoints(selectedItems);

				if (!tasksWithSecondWayPoints.length) {
					toastr.error(TranslationService.instant('PLANNING.SELECT_AT_LEAST_ONE_ROUTE'));
					return;
				}
				var tasksDropOffWayPoints = WayPointsService.getTasksDropOffPoints(selectedItems);

				tasksDropOffWayPoints = _.filter(tasksDropOffWayPoints, function (wp) {
					return wp.scheduled_at !== null;
				});

				const optimizationType =
					optimizationTypesService.getConfiguredOptimizationType(selectedItems[0].team_id) ||
					OPTIMIZATION_TYPES.ANONYMOUS;

				if (optimizationType === OPTIMIZATION_TYPES.COMBINED) {
					ReactModals.setIsOptimizationModalOpenerOpen({
						selectedTasks: $scope.selectedItems,
						isOptimizeNewAndSaveExisting: true
					});
				} else {
					OptimizeModalService.open({
						dispatch: false,
						automaticOptimization: false,
						selectedTasks: selectedItems,
						drivers: $scope.employees,
						type: optimizationType,
						tasksScheduledDate: tasksDropOffWayPoints[0].scheduled_at,
						preserveCurrentRuns: true
					});
				}
			};

			$scope.printData = null;
			$scope.print = function () {
				var pwindow = window.open(
					'',
					'_blank',
					'width=1084,height=600,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no'
				);
				pwindow.window.focus();
				pwindow.document.write(
					'<html>' + '<head>' + '</head>' + '<body>' + $scope.printData + '</body>' + '</html>'
				);
				pwindow.onload = function () {};
				setTimeout(function () {
					pwindow.print();
				}, 1);
			};

			$scope.handleReleaseToDrivers = function (tasksIds) {
				Tasks.releaseToDrivers({ ids: tasksIds })
					.then(function (result) {
						if (result.success) {
							toastr.success('Tasks were sent to drivers');
							if (result.data && result.data.emails_to_drivers_message) {
								toastr.warning(result.data.emails_to_drivers_message);
							}
							if (result.data && result.data.print_on_apply_html) {
								$scope.printData = result.data.print_on_apply_html;
							}
						} else {
							if (result.message) {
								toastr.error(result.message);
							}
						}
					})
					.catch(function (reason) {
						toastr.error(reason.message);
					})
					.finally(function () {
						$scope.pendingReleaseRequest = false;
					});
			};

			$scope.overlappingRunIds = new Set();

			var isRunInFilteredTimeRange = function (run) {
				const startTimeFilter = $scope.filterObject.timeWindowStart;
				const endTimeFilter = $scope.filterObject.timeWindowEnd;

				return (
					(startTimeFilter &&
						endTimeFilter &&
						moment(startTimeFilter).isSameOrBefore(run.scheduled_start_time) &&
						moment(endTimeFilter).isSameOrAfter(run.scheduled_end_time)) ||
					(startTimeFilter == null && endTimeFilter == null)
				);
			};

			var getRunsByPlannedRouteId = function () {
				return _.groupBy(
					RunsService.getAllFromStore().filter(run => run.ended_at == null && isRunInFilteredTimeRange(run)),
					'planned_route_id'
				);
			};

			var calculateRunIdsOfOverlappingRunsByTime = function () {
				if ($scope.plannedRoutes.length === 0) {
					return;
				}
				$scope.overlappingRunIds.clear();

				const runsByPlannedRouteID = getRunsByPlannedRouteId();
				const arraysOfRunsWithTheSamePlannedRouteId = Object.values(runsByPlannedRouteID).filter(
					item => item.length > 1
				);

				const overlappingRunIdsResult = new Set();

				arraysOfRunsWithTheSamePlannedRouteId.forEach(arrayOfRuns => {
					RunsService.getOverlappedRunIds(arrayOfRuns).forEach(item => {
						overlappingRunIdsResult.add(item);
					});
				});

				overlappingRunIdsResult.forEach(item => {
					$scope.overlappingRunIds.add(item);
				});
			};

			var throttledCalculateRunIdsOfOverlappingRuns = _.throttle(
				calculateRunIdsOfOverlappingRunsByTime,
				DEFAULT_RT_THROTTLE
			);

			var handleRunChanges = function () {
				throttledCalculateRunIdsOfOverlappingRuns();
				throttledRefreshData();
			};

			$scope.moveTasksToDispatch = function (tasksIds) {
				try {
					$scope.handleReleaseToDrivers(tasksIds);
				} catch (e) {
					$scope.pendingReleaseRequest = false;
				}
			};

			$scope.handleDeployClicked = function () {
				if ($scope.pendingReleaseRequest) {
					return;
				}

				$scope.pendingReleaseRequest = true;

				if ($scope.selectedItems.length) {
					const partialRunIds = RunsService.getPartialRunIdsByTasks(
						$scope.selectedItems,
						TasksData.getPlanningTasks()
					);
					if (Object.keys(partialRunIds).length > 0) {
						ReactModals.openPartialRunsActionsModal({
							onResponse: $scope.moveTasksToDispatch,
							partialRunsSource: PartialRunsSources.MOVE_FROM_PLANNING_TO_DISPATCH,
							selectedTasksIds: $scope.selectedItems.map(task => task.id),
							partialRunIds: Object.keys(partialRunIds)
						});
					} else {
						$scope.moveTasksToDispatch($scope.selectedItems.map(task => task.id));
					}
				} else {
					dialogSrv
						.confirmDialogAsync({
							title: 'Warning',
							message: 'Are you sure you want to send all the tasks to drivers?'
						})
						.result.then(function () {
							const tasks = TasksData.getPlanningTasks();
							var tasksIds = tasks.map(task => task.id);
							$scope.handleReleaseToDrivers(tasksIds);
						})
						.catch(function () {
							$scope.pendingReleaseRequest = false;
						});
				}
			};

			const throttledRefreshData = _.throttle(function () {
				refreshData();
			}, DEFAULT_RT_THROTTLE);

			const throttledRefreshGrid = _.throttle(function () {
				refreshGrid();
			}, 2000);

			let routesPlannerWatchUnbinder;

			function waitForRoutesPlannerToClose() {
				if (routesPlannerWatchUnbinder) {
					return;
				}

				routesPlannerWatchUnbinder = $scope.$watch(
					() => isRoutesPlannerOpen(),
					(newValue, oldValue) => {
						if (newValue !== oldValue) {
							refreshData();
							routesPlannerWatchUnbinder();
							routesPlannerWatchUnbinder = undefined;
						}
					}
				);
			}

			function refreshData() {
				if (isRoutesPlannerOpen()) {
					waitForRoutesPlannerToClose();
					return;
				}

				const newDataFunc = Task.shouldUseNewApi() && TasksData.getPlanningTasks;
				$scope.grid.refreshData(newDataFunc, $scope.groupingModel.selectedOption?.value);
			}

			function refreshGrid() {
				if (isRoutesPlannerOpen()) {
					waitForRoutesPlannerToClose();
					return;
				}

				$scope.grid.refresh();
			}

			function isRoutesPlannerOpen() {
				return !!document.getElementById('routes-planner-manager');
			}

			var handleListUpdate = function () {
				calculateRunIdsOfOverlappingRunsByTime();
				throttledRefreshData();
			};

			var subscriptions = [];

			$scope.onGridInited = function (grid, dataView) {
				dataView.setFilter(GridColumnsService.getFilterFuncForGrid(grid));
				refreshData();
				$scope.grid.ready = true;

				if ($scope.grid.refreshFilters) {
					$scope.grid.refreshFilters();
				}

				$scope.$on('task list update', handleListUpdate);

				$scope.$on('lazy task list update', handleListUpdate);

				$rootScope.$on('employees list update', function () {
					Employees.drivers().then(function (drivers) {
						$scope.employees = drivers;
						throttledRefreshGrid();
					});
				});

				subscriptions.push(
					...[
						ManagedAttributesService.listenForChanges(function () {
							throttledRefreshData();
						}),
						RunsService.onCreate(handleRunChanges),
						RunsService.onUpdate(handleRunChanges),
						RunsService.onDelete(handleRunChanges)
					]
				);
			};
			/**
			 * init function to sync promises
			 */
			$scope._init = function () {
				var initPromises = [MerchantConfigurations.$refresh(), Authentication.featureFlags()];

				$q.all(initPromises).then(function (initResult) {
					$scope.merchantConfiguration = _.clone(MerchantConfigurations);

					if (!emptyFilteringEnabled) {
						TasksLoader.loadPlanningForTable({
							columnsKey: GRID_COLUMNS_KEY,
							grouping: SlickGridConfigService.getGrouping(
								GRID_DATA.PLANNING.id,
								'planning_taskGridGroupMode'
							)
						});
					}

					var promises = [
						Employees.drivers(),
						Tags.all(),
						DriverConfigurations.isClusterModeEnabled(),
						PlannedRoutesService.getAll(),
						UserTypes.getAllAsync(),
						VehiclesService.getAll(),
						VehicleTypesService.getAll(),
						SkillsService.getAllSkills(),
						Application.isInstalledByUuid(Application.APPLICATIONS.RealtimeEta)
					];

					if (MerchantConfigurations.enable_teams) {
						promises.push(Teams.all());
					}

					// featureFlags are required for this
					const presetViewsEnabled = PresetViewsService.getPresetViewsEnabled();
					if (presetViewsEnabled) {
						promises.push(PresetViewsService.getPresetsReadyPromise());
					}

					// featureFlags are required for this
					if (CustomAttributesService.attributesEnabled) {
						initPromises.push(CustomAttributesService.getAll());
					}

					promises.push(ManagedAttributesService.loadAttributes());

					$q.all(promises).then(function (results) {
						if (presetViewsEnabled || CustomAttributesService.attributesEnabled) {
							$scope.initGridColumns();
						}

						var featureFlags = initResult[1];
						var drivers = results[0];
						var tags = results[1];
						var isClusterModeEnabled = results[2];
						var plannedRoutes = results[3];
						var userTypes = results[4];
						var vehicles = results[5];
						var vehicleTypes = results[6];
						SkillsService.setSkillsById(results[7]);
						_realtimeEtaEnabled = results[8];
						var teams = results[9];

						ServiceAreasService.loadAll().catch(function (e) {
							console.error('Could not load ServiceArea', e);
						});

						$scope.vehicleTypeByVehicleId = VehicleTypesService.getVehicleTypeByVehicleIds(
							vehicles,
							vehicleTypes
						);

						$scope.vehiclesById = VehiclesService.getVehiclesById(vehicles);
						Teams.setTeamHasTrailer(teams, vehicles, $scope.vehicleTypeByVehicleId);
						$scope.vehicleTypes = vehicleTypes;
						$scope.teams = teams;
						$scope.tags = tags;
						$scope.isClusterModeEnabled = isClusterModeEnabled;
						$scope.allowModifyPlannedRouteIdentifier = Authentication.currentUser().has_access(
							PRIVILEGES_TYPES.MODIFY_PLANNED_ROUTE_IDENTIFIER
						);

						$scope.enableRoutesPlanner =
							MerchantConfigurations.applications.some(Application.isRouteOptimizer2) &&
							Authentication.currentUser().has_access(
								PRIVILEGES_TYPES.ALLOW_USING_ROUTE_PLANNER_IN_PLANNING
							) !== false;

						$scope.doNotMultipleWeightByQuantity =
							MerchantConfigurations.ignore_inventory_quantity_for_capacity_calculation || false;
						$scope.enableRouteOptimization =
							_.some(MerchantConfigurations.applications, Application.isRouteOptimizer) &&
							Authentication.currentUser().has_access(PRIVILEGES_TYPES.OPTIMIZE_ORDERS);
						$scope.employees = drivers;
						$scope.plannedRoutes = plannedRoutes;
						$scope.initGroupingModel();
						$scope.tasks = TasksData.getPlanningTasks($scope.groupingModel.selectedOption);
						$scope.userTypes = _.get(userTypes, 'user_types');

						$scope.shouldFilterLinkedDoneTasks = featureFlags.should_filter_linked_done_tasks;

						calculateBulkAssignStatus();
					});

					$scope.initGridColumns();
				});
			};

			let isFilterInit = false;

			var refreshFiltersOnFiltersChange = function (event, removeMissingKeysFromPrevFilters) {
				if (removeMissingKeysFromPrevFilters) {
					ObjectUtilsService.deleteMissingKeys($scope.filterObject, event);
				}

				const previousSelectedTeams = $scope.filterObject.selectedTeams?.slice();
				Object.assign($scope.filterObject, event);

				if (emptyFilteringEnabled) {
					let isSelectedTeamsChanged = false;

					if (!previousSelectedTeams && event.selectedTeams) {
						isSelectedTeamsChanged = true;
					} else if (previousSelectedTeams && !event.selectedTeams) {
						isSelectedTeamsChanged = true;
					} else if (previousSelectedTeams && event.selectedTeams) {
						if (previousSelectedTeams.length === event.selectedTeams.length) {
							const previousTeamsSorted = previousSelectedTeams.sort((a, b) => a - b);
							const currentTeamsSorted = event.selectedTeams.slice().sort((a, b) => a - b);
							isSelectedTeamsChanged = !previousTeamsSorted.every(
								(value, index) => value === currentTeamsSorted[index]
							);
						} else {
							isSelectedTeamsChanged = true;
						}
					}

					if (isSelectedTeamsChanged) {
						if (isFilterInit) {
							$window.location.reload();
							return;
						} else {
							TasksLoader.loadPlanningForTable({
								columnsKey: GRID_COLUMNS_KEY,
								grouping: SlickGridConfigService.getGrouping(
									GRID_DATA.PLANNING.id,
									'planning_taskGridGroupMode'
								),
								teamIds: $scope.filterObject.selectedTeams,
								emptyFilteringEnabled: true
							});
						}

						isFilterInit = true;
					}
				}

				if ($scope.grid.refreshFilters) {
					$scope.grid.refreshFilters();
				}
			};

			CrossApplicationService.on('FILTER_CHANGE', refreshFiltersOnFiltersChange);

			$scope.handlePrintClicked = function () {
				var tasks = _.isEmpty($scope.selectedItems) ? TasksData.getPlanningTasks() : $scope.selectedItems;
				DispatcherPrintService.handlePrintClicked(tasks);
			};

			$scope.handleRoutesPlanner = function () {
				const partialRunIds = RunsService.getPartialRunIdsByTasks(
					$scope.selectedItems,
					TasksData.getPlanningTasks()
				);
				let routesPlannerTasks = $scope.selectedItems;
				let isPartialRouteWasSelected = false;
				if (Object.keys(partialRunIds).length) {
					routesPlannerTasks = RunsService.getAllTasksOfRuns(TasksData.getPlanningTasks(), partialRunIds);
					isPartialRouteWasSelected = true;
				}

				RoutesPlannerService.open(routesPlannerTasks, isPartialRouteWasSelected);
			};

			//==============================================
			// BackgroundTask
			//==============================================

			$scope.handleRescheduleClicked = function () {
				TasksModalService.openScheduleTasksDialog($scope.selectedItems);
			};

			$scope.showDonePlanningAction = function () {
				return $scope.groupingModel.selectedOption?.value.includes('Route');
			};

			$scope.groupTasksByRun = function (tasks) {
				return _.groupBy(tasks, function (task) {
					return task.run_id || NO_ROUTE;
				});
			};

			$scope.getTasksWithSecondWayPoints = function (tasks) {
				return _.filter(tasks, function (task) {
					return _.some(task.way_points, function (wp) {
						return wp.position === 2 && wp.scheduled_at !== null;
					});
				});
			};

			$scope.donePlanningClicked = function () {
				var runIds = [];
				var selectedAndScheduledTasksByRun = $scope.groupTasksByRun($scope.selectedItems);
				var tasksByRun = $scope.groupTasksByRun(TasksData.getPlanningTasks());

				_.each(selectedAndScheduledTasksByRun, function (tasks, runId) {
					runId = Number(runId);
					if (runId !== NO_ROUTE) {
						var secondWayPointsFromTasksWithScheduledAt = $scope.getTasksWithSecondWayPoints(tasks);

						if (secondWayPointsFromTasksWithScheduledAt.length === tasksByRun[runId].length) {
							runIds.push(runId);
						} else {
							console.log(
								'Selected tasks of run:',
								tasks,
								'Existing tasks of run:',
								tasksByRun[runId],
								'Way points with position #2 and scheduled_at:',
								secondWayPointsFromTasksWithScheduledAt
							);
							toastr.error(
								TranslationService.instant(
									'DISPATCH.ERROR_MARK_DONE_RUN_AS_PLANNING',
									tasks[0].route_name
								)
							);
						}
					}
				});

				RunsService.markDonePlanningToRuns(runIds);
			};

			function prepareDriverPriceText(tasks) {
				var totalPrice = Tasks.calcMaxRoutePrice(tasks);

				if (totalPrice === 0) {
					return '';
				}

				return TasksGrouping.getIconWithDetailsHtml({
					icon: 'total-price-icon',
					className: 'price',
					count: totalPrice.toLocaleString('en-US'),
					totalCount: 0,
					showTotal: false
				});
			}

			$scope._init();

			// comment to avoid any unexpected(opaque) UI flow when the PresetView is applied or data loaded the controller is fully rerendered
			CrossApplicationService.on(CROSS_APP_ACTIONS.PRESET_VIEWS_UPDATED, $scope._init);
		}
	);
